import React, { useState, useEffect, useRef } from "react";
import parsedPhoneNumber, { PhoneNumber } from "libphonenumber-js/mobile";
import { Button } from "../../../Button";
import { FormErrorText } from "../../../form-utils";
import { PhoneForm } from "./components/PhoneForm";
import { EmailForm } from "./components/EmailForm";
import { CodeForm } from "./components/CodeForm";
import {
  WarningIcon,
  ErrorMessageContainer,
} from "../../../InputAdornment/styles";
import {
  ContentV2,
  OTPContentV2,
  OTPText,
  ResetStateText,
  MainContainer,
  SubTitleContainer,
  TitleContainer,
  SignInSectionTab,
  SignInTabContainer,
  ButtonWrapper,
  Form,
  TabbedContainer,
  HLine,
} from "./styles";
import { BSPBody2 } from "../../../BSPBody2";
import { BSPH5 } from "../../../BSPH5";

import { INPUT_FOCUSED, typewriterTrack } from "../../../../../lib/events";
import { SegmentPropFunctions } from "../../../../segment";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { GlobalStorage } from "../../../../../utils/global-storage";
import { Channel, Source } from "../../../../../../typewriter/segment";

enum SelectedTab {
  EMAIL = "EMAIL",
  PHONE = "PHONE",
}

type Props = {
  signUpVerification?: (initId: string) => void;
  onSubmitVerificationCode: (OTP: string) => Promise<void>;
  onSendVerificationCode: (profileIdentifier: string) => Promise<void>;
  isSsoEnabled: boolean;
  onSsoLoginOptionSelected?: (email: string) => Promise<void>;
  onGoogleAuthSuccess: (idToken: string) => Promise<void>;
  onGoogleAuthFailure?: (error: Error) => Promise<void>;
  segment: SegmentPropFunctions;
  googleApiKey: string;
  googleMapsApiKey: string;
  source: "PWA" | "ADMIN";
};

export function MainForm({
  googleApiKey,
  googleMapsApiKey,
  segment,
  source,
  isSsoEnabled,
  onSsoLoginOptionSelected: _onSsoLoginOptionSelected,
  onGoogleAuthSuccess,
  onGoogleAuthFailure,
  signUpVerification,
  onSendVerificationCode,
  onSubmitVerificationCode: _onSubmitVerificationCode,
}: Props) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const timeoutRef = useRef<number | undefined>();

  const [loadingCode, setLoadingCode] = useState(false);
  const [loadingSso, setLoadingSso] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [gauthError, setGauthError] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [selectedTab, setSelectedTab] = useState(SelectedTab.EMAIL);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [phase, setPhase] = useState("entering");
  const [inputFocused, setInputFocused] = useState(false);

  const isPhoneTab = selectedTab === SelectedTab.PHONE;
  const isEmailTab = selectedTab === SelectedTab.EMAIL;

  const typewriterProps = {
    channel: selectedTab.toLowerCase() as Channel,
    to: isPhoneTab ? phoneNumber : email,
    source: source as Source,
  };

  useEffect(() => {
    setError(null);
    typewriterTrack("signinViewed", {});

    const store = new GlobalStorage();
    store.removeItem(`isGauth-${source.toLowerCase()}`);

    const existingAuthInitId = localStorage.getItem("authInfo");
    if (existingAuthInitId) {
      const authInfo = JSON.parse(existingAuthInitId);
      setEmail(authInfo.email);
      // TODO: We need to find another way of doing this since it's performing extra
      // re-renders due to a state change in the parent
      signUpVerification?.(authInfo.initId);

      setLoadingCode(false);
      setError(null);
      setPhase("confirming");
    }

    return () => {
      window.clearTimeout(timeoutRef.current);
    };

    // This effect must be executed just once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitSendVerificationCode = async () => {
    try {
      setLoadingCode(true);
      setLoadingSso(false);

      await onSendVerificationCode(phoneNumber || email);

      typewriterTrack("successSendVerificationCode", typewriterProps);

      // TODO: Review this, we are using this in several places
      // it's difficult to follow where we are setting it, reading or deleting
      localStorage.removeItem("authInfo");

      setLoadingCode(false);
      setError(null);
      setPhase("confirming");
    } catch (err) {
      const { code, errorMessage, errorName, message } = (err || {}) as any;
      typewriterTrack("failedSendVerificationCode", {
        ...typewriterProps,
        errorCode: code,
        errorName: errorName,
        errorMessage: errorMessage,
      });

      setError(message);
      setLoadingCode(false);
    }
  };

  const onPhoneInputChange = (country: string, phoneNumber: string) => {
    setPhoneNumber(phoneNumber);
    setCountry(country);
  };

  const onSubmitVerificationCode = async () => {
    try {
      setLoadingCode(true);
      setLoadingSso(false);

      await _onSubmitVerificationCode(confirmationCode);

      setLoadingCode(false);
    } catch (err) {
      const { code, message } = (err || {}) as any;
      typewriterTrack("failedVerifyVerificationCode", {
        ...typewriterProps,
        errorName: code,
        errorMessage: message,
      });
      setError(message);
      setLoadingCode(false);

      return;
    }

    typewriterTrack("signinVerified", typewriterProps);
  };

  const onSsoLoginOptionSelected = async () => {
    try {
      setLoadingCode(false);
      setLoadingSso(true);

      await _onSsoLoginOptionSelected?.(email);

      setLoadingSso(false);
    } catch (err) {
      const { message } = (err || {}) as Error;
      setError(message);
      setLoadingSso(false);
    }
  };

  const onSmsSelected = () => {
    resetState();
    setSelectedTab(SelectedTab.PHONE);
    inputRef.current?.focus();
    typewriterTrack("signinOptionSelected", { name: "phone" });
  };

  const onEmailSelected = () => {
    resetState();
    setSelectedTab(SelectedTab.EMAIL);
    inputRef.current?.focus();
    typewriterTrack("signinOptionSelected", { name: "email" });
  };

  const handleCountryChange = (countryCode: string) => {
    typewriterTrack("phoneCountryChanged", {
      previousCountry: country,
      selectedCountry: countryCode,
    });

    setCountry(countryCode);
  };

  const handleInputFocusChange = (inputFocused: boolean) => {
    // Clear any errors when the input is refocused.
    if ((error || gauthError) && inputFocused) {
      setError(null);
      setGauthError(null);
    }
    if (inputFocused) {
      void segment.track(
        INPUT_FOCUSED.name,
        INPUT_FOCUSED.properties({
          inputName: isPhoneTab ? "PhoneInput" : "EmailInput",
        })
      );
    }
    /**
     * Within mobile safari we position the "Next" button based on the input focus state.
     * However, due to how safari bubbles events, we cannot use a CSS selctor to position
     * the button on the input focus state, as the button reacts to the input blur and moves
     * before a tap is registered.
     *
     * Instead, we track input focus state manually, and update async relative to the touch
     * event handling.
     */
    timeoutRef.current = window.setTimeout(() => {
      setInputFocused(inputFocused);
    }, 0);
  };

  const onGAuthSuccess = async (credentialResponse: CredentialResponse) => {
    setGauthError(null);
    const credJwt = credentialResponse.credential;

    if (credJwt) {
      let subjectEmail = "";
      try {
        const decoded = jwt_decode<{ email: string }>(credJwt);
        subjectEmail = decoded?.email;
      } catch (err) {
        //no-op
      }

      try {
        await onGoogleAuthSuccess(credJwt);

        const store = new GlobalStorage();
        store.setItem(`isGauth-${source.toLowerCase()}`, "true");
        typewriterTrack("googleSigninSuccess", {
          source: source.toLowerCase(),
          email: subjectEmail,
        });
      } catch (err) {
        const { message } = (err || {}) as Error;
        setGauthError(message);
        typewriterTrack("googleSigninFailure", {
          source: source.toLowerCase(),
          cause: message,
        });
      }
    } else {
      setGauthError("Could not find credential in response !!");
      typewriterTrack("googleSigninFailure", {
        source: source.toLocaleLowerCase(),
        cause: "Could not obtain google credential in signin response",
      });
    }
  };

  const onGAuthError = async (message: string) => {
    setGauthError(message);

    await onGoogleAuthFailure?.(new Error(message));

    typewriterTrack("googleSigninFailure", {
      source: source.toLowerCase(),
    });
  };

  const resetState = () => {
    setError(null);
    setGauthError(null);
    setConfirmationCode("");
    setPhase("entering");
    setPhoneNumber("");
    setEmail("");
  };

  if (phase === "confirming") {
    return (
      <Form
        key="confirmation-form"
        onSubmit={(e) => {
          e.preventDefault();
          void onSubmitVerificationCode();
        }}
        phase={phase}
      >
        <OTPContentV2>
          <TitleContainer>
            <BSPH5 style={{ fontWeight: 500 }}>Verification</BSPH5>
          </TitleContainer>

          <OTPText>
            <SubTitleContainer>
              <BSPBody2>
                A code was sent to{" "}
                <b>
                  {isPhoneTab
                    ? (
                        parsedPhoneNumber(phoneNumber) as PhoneNumber
                      ).formatNational()
                    : email}
                </b>
              </BSPBody2>
            </SubTitleContainer>

            <ResetStateText>
              <BSPBody2 onClick={() => resetState()}>
                Change {isPhoneTab ? "phone number" : "email"}
              </BSPBody2>
            </ResetStateText>
          </OTPText>

          <CodeForm
            error={!!error}
            onResendRequested={async () => {
              typewriterTrack("newCodeRequested", typewriterProps);

              await onSubmitSendVerificationCode();
            }}
            onFocus={() => handleInputFocusChange(true)}
            onBlur={() => handleInputFocusChange(false)}
            onChange={(code) => setConfirmationCode(code)}
          />
          {error && (
            <ErrorMessageContainer>
              <WarningIcon />
              <FormErrorText errors={error} />
            </ErrorMessageContainer>
          )}

          <ButtonWrapper key="confirmation-submit" inputFocused={inputFocused}>
            <Button
              id="phoneInput.verify-and-sign-in"
              title="Sign-in"
              type="submit"
              disabled={confirmationCode.length < 6}
              loading={loadingCode && !error}
              text="Sign-in"
              align="center"
            />
          </ButtonWrapper>
        </OTPContentV2>
      </Form>
    );
  }

  return (
    <>
      <MainContainer>
        <SubTitleContainer>
          <BSPBody2>Use your phone number or company email</BSPBody2>
        </SubTitleContainer>
        <TabbedContainer>
          <SignInTabContainer>
            <SignInSectionTab active={isEmailTab} onClick={onEmailSelected}>
              <BSPBody2>Email</BSPBody2>
            </SignInSectionTab>
            <SignInSectionTab active={isPhoneTab} onClick={onSmsSelected}>
              <BSPBody2>Phone</BSPBody2>
            </SignInSectionTab>
          </SignInTabContainer>
        </TabbedContainer>
        {isPhoneTab ? (
          <Form
            key="phone-form"
            phase={phase}
            onSubmit={(e) => {
              e.preventDefault();
              void onSubmitSendVerificationCode();
            }}
          >
            <ContentV2>
              <PhoneForm
                handleCountryChange={handleCountryChange}
                errors={error ? [error] : []}
                onFocus={() => handleInputFocusChange(true)}
                onBlur={() => handleInputFocusChange(false)}
                onChange={onPhoneInputChange}
                loading={loadingCode}
                inputRef={inputRef}
                inputFocused={inputFocused}
                googleApiKey={googleApiKey}
                googleMapsApiKey={googleMapsApiKey}
              />
            </ContentV2>
          </Form>
        ) : (
          <Form
            key="email-form"
            phase={phase}
            onSubmit={(e) => {
              e.preventDefault();
              void onSubmitSendVerificationCode();
            }}
          >
            <ContentV2>
              <EmailForm
                errors={error ? [error] : undefined}
                onFocus={() => handleInputFocusChange(true)}
                onBlur={() => handleInputFocusChange(false)}
                value={email}
                onChange={(email) => setEmail(email)}
                isSsoEnabled={isSsoEnabled}
                onSsoClicked={onSsoLoginOptionSelected}
                loadingCode={loadingCode}
                loadingSso={loadingSso}
                inputRef={inputRef}
                inputFocused={inputFocused}
              />
            </ContentV2>
          </Form>
        )}
      </MainContainer>
      <HLine />
      <br />
      <GoogleLogin
        onSuccess={onGAuthSuccess}
        onError={() => onGAuthError("Login failed !")}
        auto_select={false}
      />
      <br />
      {gauthError && (
        <ErrorMessageContainer>
          <WarningIcon />
          <FormErrorText errors={gauthError as string} />
        </ErrorMessageContainer>
      )}
    </>
  );
}
