import React from "react";
import { FormHelperText as StyledFormHelperText } from "./styled";
import { BSPBody2 } from "../../BSPBody2";

import { FormHelperTextProps } from "@mui/material/FormHelperText";

export type Props = FormHelperTextProps &
  React.PropsWithChildren<{
    id?: string;
    errors?: string[] | string;
  }>;

export function FormErrorText({ errors, ...props }: Props) {
  const getErrorMessage = () => {
    if (!errors) {
      return null;
    }

    if (typeof errors === "string") {
      return errors;
    }

    if (Array.isArray(errors) && typeof errors[0] === "string") {
      return errors[0];
    }

    return null;
  };

  return (
    <StyledFormHelperText {...props}>
      <BSPBody2>{getErrorMessage()}</BSPBody2>
    </StyledFormHelperText>
  );
}
