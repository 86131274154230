import { styled } from "@mui/material/styles";

export const InputContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
`;

export const Container = styled("div")`
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
`;

export const OTPHeader = styled("div")`
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  flex-direction: row;
`;

export const Digit = styled("input")<{
  error: boolean;
}>`
  border: 1px solid;
  border-color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiDark};
  height: 48px;
  width: 15%;
  box-shadow: none;
  text-align: center;
  font-size: 21px;
  padding: 0;
  -webkit-appearance: unset;
  color: ${({ error, theme }) =>
    error
      ? theme.kinetic.v2.palette.rgb.negative
      : theme.kinetic.v2.palette.rgb.grayTextDark};
  &:focus {
    border-color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextDark};
    color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextDark};
  }
`;

Digit.defaultProps = {
  type: "text",
  inputMode: "numeric",
};

export const ResendCodeButton = styled("span")<{ disabled: boolean }>`
  line-height: 16px;
  color: ${({ disabled, theme }) =>
    disabled
      ? theme.kinetic.v2.palette.rgb.grayTextLighter
      : theme.kinetic.v2.palette.rgb.blue};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;
