import typewriter from "../../typewriter/segment";
import { getIdentifyInfo } from "./segment";

export const typewriterTrack = <
  E extends keyof typeof typewriter,
  P extends Partial<Parameters<(typeof typewriter)[E]>[0]>
>(
  event: E,
  props?: P
) => {
  const traits = getIdentifyInfo();
  const method: any = typewriter[event];
  const composedProps = { ...(props ?? {}), sessionId: traits.sessionId };
  method(composedProps, {
    context: { traits: traits.prevIdentifyParams },
  });
};

export const INPUT_FOCUSED = {
  name: "Input Focused",
  properties: (p: { inputName: string }) => p,
};
