import canStoreIn from "./can-store-in";

const shim = new Map<string, string>();

const storageEnabled = canStoreIn("localStorage");

export class GlobalStorage {
  public get length() {
    if (storageEnabled) {
      return localStorage.length;
    }

    return shim.size;
  }

  public clear(): void {
    if (storageEnabled) {
      localStorage.clear();

      return;
    }

    shim.clear();
  }

  public getItem(k: string): string | null | undefined {
    if (storageEnabled) {
      return localStorage.getItem(k);
    }

    if (shim.has(k)) {
      return shim.get(k);
    } else {
      return null;
    }
  }

  public key(n: number): any {
    if (storageEnabled) {
      return localStorage.key(n);
    }

    const arr = Array.from(shim.keys());

    return arr[n];
  }

  public removeItem(k: string): void {
    if (storageEnabled) {
      localStorage.removeItem(k);

      return;
    }

    shim.delete(k);
  }

  public setItem(k: string, v: string): void {
    if (storageEnabled) {
      localStorage.setItem(k, v);
    }

    shim.set(k, v);
  }

  public getAll(): any {
    if (storageEnabled) {
      return { ...localStorage };
    }

    return Object.fromEntries(shim);
  }
}

export default GlobalStorage;
