import React from "react";
import { BSPSubtext } from "../BSPSubtext";
import {
  Body,
  TopContainer,
  Container,
  TopContent,
  LogoContainer,
  BigSpringLogo,
  Content,
  CopyrightContainer,
} from "./styles";
import { MainForm } from "./components/MainForm";
import { SegmentPropFunctions } from "../../segment";

type Props = {
  signUpVerification?: (initId: string) => void;
  onSubmitVerificationCode: (OTP: string) => Promise<void>;
  onSendVerificationCode: (profileIdentifier: string) => Promise<void>;
  isSsoEnabled: boolean;
  onSsoLogin?: (email: string) => Promise<void>;
  onGoogleAuthSuccess: (idToken: string) => Promise<void>;
  onGoogleAuthFailure?: (error: Error) => Promise<void>;
  googleApiKey: string;
  googleMapsApiKey: string;
  segment: SegmentPropFunctions;
  source: "PWA" | "ADMIN";
};

export function Login({
  onSubmitVerificationCode,
  onSendVerificationCode,
  signUpVerification,
  isSsoEnabled,
  onSsoLogin,
  onGoogleAuthSuccess,
  onGoogleAuthFailure,
  googleApiKey,
  googleMapsApiKey,
  segment,
  source,
}: Props) {
  return (
    <Body>
      <Container>
        <TopContainer>
          <TopContent>
            <LogoContainer>
              <BigSpringLogo />
            </LogoContainer>
          </TopContent>
          <Content>
            <MainForm
              source={source}
              segment={segment}
              googleApiKey={googleApiKey}
              googleMapsApiKey={googleMapsApiKey}
              onSendVerificationCode={onSendVerificationCode}
              onSubmitVerificationCode={onSubmitVerificationCode}
              signUpVerification={signUpVerification}
              isSsoEnabled={isSsoEnabled}
              onSsoLoginOptionSelected={onSsoLogin}
              onGoogleAuthSuccess={onGoogleAuthSuccess}
              onGoogleAuthFailure={onGoogleAuthFailure}
            />
          </Content>
        </TopContainer>
        <CopyrightContainer>
          <BSPSubtext>
            Copyright &copy; {new Date().getFullYear()} BigSpring. All rights
            reserved.
          </BSPSubtext>
          <BSPSubtext>
            bigspring-cc@
            {process.env.NEXT_PUBLIC_VERSION ||
              process.env.VERSION ||
              "unknown-version"}
          </BSPSubtext>
        </CopyrightContainer>
      </Container>
    </Body>
  );
}
