import { styled } from "@mui/material/styles";

export const Container = styled("div")`
  width: 100%;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
`;
export const ButtonWrapper = styled("div")<{
  inputFocused: boolean;
}>`
  margin-top: 20px;
  box-sizing: border-box !important;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
  width: 100%;
`;
export const HideRecaptcha = styled("div")<{
  inputFocused: boolean;
  isAndroid: boolean;
}>`
  position: absolute;
  margin-top: -30px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  ${({ inputFocused, isAndroid }) =>
    inputFocused && !isAndroid && `display: none;`};
`;
