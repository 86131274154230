const canStoreIn = (storage: "localStorage" | "sessionStorage"): boolean => {
  try {
    const store: Storage = window[storage];
    const key = "__storage_test__";

    store.setItem(key, "ok");
    store.removeItem(key);

    return true;
  } catch (_err) {
    return false;
  }
};

export default canStoreIn;
