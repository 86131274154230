import React, { useState, MouseEvent, ReactNode } from "react";
import { TextField as StyledTextField } from "./styles";
import {
  InputLabel as StyledInputLabel,
  StartInputAdornment as StyledStartInputAdornment,
  EndInputAdornment as StyledEndInputAdornment,
  ErrorMessageContainer,
  WarningIcon,
} from "../InputAdornment/styles";
import {
  TextFieldProps as TextFieldPropsBase,
  StandardTextFieldProps,
} from "@mui/material/TextField";
import { FormErrorText } from "../form-utils";

export type TextFieldProps = TextFieldPropsBase & {
  id?: string;
  tabIndex?: number;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  resize?: "vertical" | "horizontal" | "both" | "none";
  valid?: boolean;
  errors?: string[] | string;
};

function StartAdornment({ icon }: { icon: ReactNode }) {
  return (
    <StyledStartInputAdornment position="start">
      {icon}
    </StyledStartInputAdornment>
  );
}

function EndAdornment({ icon }: { icon: ReactNode }) {
  return (
    <StyledEndInputAdornment position="end">{icon}</StyledEndInputAdornment>
  );
}

export function TextField({
  id,
  type,
  name,
  disabled,
  label,
  placeholder,
  startIcon,
  endIcon,
  helperText,
  resize = "none",
  onClick: _onClick,
  valid = true,
  tabIndex,
  errors,
  ...props
}: TextFieldProps) {
  const [visited, setVisited] = useState(false);

  const onClick = (e: MouseEvent<HTMLInputElement>) => {
    setVisited(true);
    _onClick?.(e);
  };

  return (
    <>
      {label && (
        <StyledInputLabel htmlFor={id || name}>{label}</StyledInputLabel>
      )}
      <StyledTextField
        id={id}
        name={name}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        className={visited ? "Input--visited" : ""}
        error={!valid}
        helperText={!errors ? helperText : undefined}
        onClick={onClick}
        {...props}
        inputProps={{
          tabIndex: disabled ? -1 : tabIndex,
          ...props.inputProps,
          style: {
            resize,
            ...(props.inputProps?.style ?? {}),
          },
        }}
        InputProps={
          {
            tabIndex: disabled ? -1 : tabIndex,
            ...props.InputProps,
            startAdornment: startIcon ? (
              <>
                <StartAdornment icon={startIcon} />
                {props.InputProps?.startAdornment}
              </>
            ) : (
              props.InputProps?.startAdornment
            ),
            endAdornment: endIcon ? (
              <>
                <EndAdornment icon={endIcon} />
                {props.InputProps?.endAdornment}
              </>
            ) : (
              props.InputProps?.endAdornment
            ),
            // Last version of styled-components seems to have issues
            // with types concatenation if one of them doesn't have the same props
            // so we are forcing the prop types to be just one of them
          } as StandardTextFieldProps["InputProps"]
        }
      />
      {!valid && errors && (
        <ErrorMessageContainer>
          <WarningIcon />{" "}
          <FormErrorText
            id={id && !valid ? `${id}-error` : undefined}
            errors={errors}
          />
        </ErrorMessageContainer>
      )}
    </>
  );
}
