import { styled } from "@mui/material/styles";

export const BSPH5 = styled("h5")`
  font-family: "Roboto", var(--bigspring-primary-font);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  margin: 0;
`;
