import React, { useEffect, useRef, useState } from "react";
import { InputContainer, Container, Digit, OTPHeader } from "./styles";
import { BSPBody2 } from "../../../../../BSPBody2";
import { ResendCode } from "./components/ResendCode";

type Props = {
  onResendRequested: () => Promise<void>;
  onChange: (code: string) => void;
  onFocus: VoidFunction;
  onBlur: VoidFunction;
  error: boolean;
};

const CODE_LENGTH = 6;
const INPUT_NAMES = Array.from({ length: CODE_LENGTH }).map(
  (_, index) => `verificationCode[${index}]`
);
const INPUT_VALUES = Array.from({ length: CODE_LENGTH }).map(() => "");

export function CodeForm({
  error,
  onResendRequested,
  onChange,
  onFocus,
  onBlur,
}: Props) {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [focus, setFocus] = useState(0);
  const [value, setValue] = useState(INPUT_VALUES);

  const handleChange =
    (idx: number) => (evt: { target: { value: string } }) => {
      const insertion = evt.target.value.split("");
      if (insertion.length) {
        const newValue = [...value];
        newValue.splice(idx, insertion.length - 1, ...insertion);
        setValue(newValue.slice(0, 6));

        setFocus(Math.min(focus + insertion.join("").length, 5));
      }
    };

  const handleKeyDown = (idx: number) => (evt: { key: string }) => {
    if (evt.key === "Backspace") {
      const trimmed = value.join("");
      const newValue = trimmed.slice(0, trimmed.length - 1).split("");
      setValue([...newValue, ...INPUT_VALUES].slice(0, 6));

      setFocus(Math.max(idx - 1, 0));
    }
  };

  useEffect(() => {
    inputRefs.current[focus]?.focus();
  }, [focus]);

  useEffect(() => {
    onChange(value.join(""));
  }, [value, onChange]);

  return (
    <Container>
      <OTPHeader>
        <BSPBody2 style={{ fontWeight: 500 }}>Enter code</BSPBody2>
        <ResendCode
          delayInSeconds={120}
          onResendRequested={onResendRequested}
        />
      </OTPHeader>
      <InputContainer>
        {INPUT_NAMES.map((name, idx) => (
          <Digit
            key={name}
            error={error}
            ref={(ref) => (inputRefs.current[idx] = ref) as any}
            onChange={handleChange(idx)}
            onKeyDown={handleKeyDown(idx)}
            value={value[idx]}
            onFocus={onFocus}
            onBlur={onBlur}
            name={name}
          />
        ))}
      </InputContainer>
    </Container>
  );
}
