import { styled } from "@mui/material/styles";
import TextFieldMui from "@mui/material/TextField";
import { Caption } from "../BSPCaption";
import { Input as InputGlobal } from "../InputAdornment/styles";

export const TextField = styled(TextFieldMui)`
  width: 100%;
  .MuiInputBase-root {
    ${InputGlobal}
  }

  .MuiFormHelperText-root {
    ${Caption}
    text-transform: uppercase;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
