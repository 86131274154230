import React, { useCallback, useEffect, useState, Ref } from "react";
import { pathOr } from "ramda";
import examples from "libphonenumber-js/examples.mobile.json";
import {
  CountryCode,
  formatIncompletePhoneNumber,
  getExampleNumber,
  NationalNumber,
  parsePhoneNumber,
} from "libphonenumber-js/mobile";
import getFlag from "country-code-emoji";
import { Grid } from "../Grid";
// TODO: There are two countries present in this array which are not supported
// by libphonenumber-js. We should use just one source of truth.
// If we are going to use libphonenumber-js, we should use the method getCountries()
// that they provide.
import { COUNTRY_CODES } from "./country-config";
import {
  Carrot,
  InputContainer,
  Input,
  SelectWrapper,
  Select,
  SyntheticSelect,
  InputWrapper,
} from "./styles";
import {
  InputLabel,
  ErrorMessageContainer,
  WarningIcon,
} from "../InputAdornment/styles";
import { FormErrorText } from "../form-utils";

export type PhoneInputProps = {
  name?: string;
  label?: string;
  value?: string;
  googleApiKey?: string;
  googleMapsApiKey?: string;
  valid?: boolean;
  autoFocus?: boolean;
  inputRef?: Ref<HTMLInputElement | null>;
  errors?: string | string[];
  defaultCountryCode?: CountryCode;
  onCountryChange?: (country: string) => void;
  onChange?: (prefix: string, phoneNumber: string, isValid: boolean) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

function isValidPhoneFormat(phone: string, country?: CountryCode) {
  try {
    const phoneNumber = parsePhoneNumber(phone, country);

    return phoneNumber.isPossible();
  } catch (e) {
    return false;
  }
}

function formatPhoneNumber(
  phone: string,
  currentPhone: string,
  country?: CountryCode
) {
  let result = formatIncompletePhoneNumber(phone, country);

  if (result === currentPhone) {
    const digits = result.replace(/\D/g, ""); //strip away non-numeric characters
    const withoutLastDigit = digits.slice(0, digits.length - 1); //remove the last digit
    result = formatIncompletePhoneNumber(withoutLastDigit, country);
  }

  return result;
}

function reconcilePhoneNumber(phone: string, country?: CountryCode) {
  const phoneNumber = parsePhoneNumber(phone, country);

  return phoneNumber.number as string;
}

function getCountryCode(phone: string) {
  const phoneNumber = parsePhoneNumber(phone);

  return phoneNumber.country;
}

function removeCountryCallingCodeFromPhone(phone: string) {
  const phoneNumber = parsePhoneNumber(phone);

  return phone.slice(phoneNumber.countryCallingCode.length + 1); // We add one to remove the + sign
}

/**
 * Phone input with country selector.
 *
 * If googleApiKey and googleMapsApiKey are passed, a request to Google Geolocation API will be made to get the current user country.
 */
export function PhoneInput({
  name,
  label,
  value,
  googleApiKey,
  googleMapsApiKey,
  valid,
  autoFocus,
  inputRef,
  errors,
  defaultCountryCode,
  onCountryChange,
  onChange,
  onFocus,
  onBlur,
}: PhoneInputProps) {
  const [phone, setPhone] = useState(value || "");
  const [country, setCountry] = useState<CountryCode>("US");
  const exampleNumber = getExampleNumber(
    country,
    examples as unknown as { [country in CountryCode]: NationalNumber }
  );

  useEffect(() => {
    async function guessCountryCode() {
      if (!googleApiKey || !googleMapsApiKey) {
        return;
      }

      try {
        const geoLocationRes = await fetch(
          `https://www.googleapis.com/geolocation/v1/geolocate?key=${googleApiKey}`,
          { method: "POST" }
        ).then((res) => res.json());

        const geoCodeRes = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${geoLocationRes.location.lat},${geoLocationRes.location.lng}&key=${googleMapsApiKey}&result_type=country`,
          { method: "POST" }
        ).then((res) => res.json());

        const country = pathOr(
          "US",
          ["results", 0, "address_components", 0, "short_name"],
          geoCodeRes
        );

        setCountry(country);
      } catch (e) {
        setCountry("US");
      }
    }

    if (defaultCountryCode && !phone) {
      setCountry(defaultCountryCode);
    } else if (!phone) {
      void guessCountryCode();
    } else {
      const country = getCountryCode(phone);

      if (country) {
        setCountry(country);

        const phoneWithoutCallingCode =
          removeCountryCallingCodeFromPhone(phone);

        setPhone(formatPhoneNumber(phoneWithoutCallingCode, "", country));
      }
    }

    // This effect must be executed just once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isValidPhoneFormat(phone, country)) {
      onChange?.(country, phone, false);
    } else {
      onChange?.(country, reconcilePhoneNumber(phone, country), true);
    }
  }, [country, onChange, phone]);

  const handleOnCountryChange = (evt: any) => {
    const nextCountry = evt.target.value;

    onCountryChange?.(nextCountry);

    setCountry(nextCountry);
  };

  const handleOnChange = useCallback(
    (text: string) => {
      const formattedPhoneNumber = formatPhoneNumber(text, phone, country);

      setPhone(formattedPhoneNumber);
    },
    [phone, country]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
        <InputContainer>
          <SelectWrapper>
            <SyntheticSelect>{getFlag(country)}</SyntheticSelect>
            <Carrot />
            <Select
              value={country}
              onChange={handleOnCountryChange}
              name={`${name}[country]`}
            >
              {COUNTRY_CODES.map(([code, name, prefix]) => (
                <option key={code} value={code}>
                  {getFlag(code)} {name} (+{prefix})
                </option>
              ))}
            </Select>
          </SelectWrapper>
          <InputWrapper>
            <Input
              valid={valid}
              value={phone}
              onChange={(evt) => handleOnChange(evt.target.value)}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={exampleNumber ? exampleNumber.formatNational() : ""}
              type="tel"
              name={name}
              autoFocus={autoFocus}
              inputRef={inputRef}
            />
          </InputWrapper>
        </InputContainer>
        {(errors?.length || 0) > 0 && (
          <ErrorMessageContainer>
            <WarningIcon /> <FormErrorText errors={errors} />
          </ErrorMessageContainer>
        )}
      </Grid>
    </Grid>
  );
}
