import { styled } from "@mui/material/styles";
import FormHelperTextMui from "@mui/material/FormHelperText";
import { Caption } from "../../BSPCaption";

type FormHelperTextProps = {
  error?: boolean;
};

export const FormHelperText = styled(FormHelperTextMui)<FormHelperTextProps>`
  ${Caption}

  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.negative};
`;
