import { css, styled } from "@mui/material/styles";

export const Caption = css`
  font-family: "Roboto", var(--bigspring-primary-font);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.025em;
`;

export const BSPCaption = styled("span")`
  ${Caption};
`;
