import React from "react";

import { ResendCodeButton } from "../../styles";
import { useTimer } from "./useTimer";
import { BSPBody2 } from "../../../../../../../BSPBody2";

const secondsFormat = {
  minimumIntegerDigits: 2,
};

type Props = {
  delayInSeconds: number;
  onResendRequested: () => Promise<void>;
};

function formatTime(valueInSeconds: number): string {
  const minutes = Math.floor(valueInSeconds / 60);
  const seconds = (valueInSeconds % 60).toLocaleString([], secondsFormat);

  return `${minutes}:${seconds}`;
}

export function ResendCode({ delayInSeconds, onResendRequested }: Props) {
  const { secondsRemaining, restartTimer } = useTimer(delayInSeconds);
  const buttonIsDisabled = secondsRemaining > 0;
  const formattedTime = formatTime(secondsRemaining);

  return (
    <ResendCodeButton
      title="Resend code"
      id="phone-login.resend-code"
      disabled={buttonIsDisabled}
      onClick={async () => {
        await onResendRequested();
        restartTimer();
      }}
    >
      <BSPBody2>
        {buttonIsDisabled ? <span> {formattedTime}</span> : "Resend code"}
      </BSPBody2>
    </ResendCodeButton>
  );
}
