import { styled } from "@mui/material/styles";
import { BigSpringLogoIcon } from "../../../../icons";

export const MainContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 64px;
  margin-left: auto;
  margin-right: auto;
  max-width: 480px;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
`;

export const TitleContainer = styled("div")`
  text-align: center;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextDark};
`;

export const SubTitleContainer = styled("div")`
  text-align: center;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
  padding-top: 24px;
`;
export const TopContent = styled("div")`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
`;

export const Body = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  min-height: 100dvh;
  max-height: 100vh;
  max-height: 100dvh;
  width: 100%;
  color: white;
  background-color: white;
  font-feature-settings: "kern", "liga", "clig" !important;
  font-kerning: auto !important;
  -webkit-font-smoothing: antialiased !important;
  font-variant-ligatures: common-ligatures contextual !important;
  text-rendering: optimizeLegibility !important;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, Helvetica, Arial,
    sans-serif;
`;

export const CopyrightContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 20px;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
`;
export const TopContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled("div")`
  background: white;
  margin: 0;
  height: 100vh;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0px 24px;
`;

export const LowerSection = styled("div")`
  border-top: 1px solid #d2d2d2;
`;

export const BigSpringLogo = styled(BigSpringLogoIcon)`
  margin-top: 64px;
  width: 160px !important;
  height: 33px !important;
  display: flex;
  flex-direction: row;
  justify-self: center;
`;
export const LogoContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ButtonContainer = styled("div")`
  margin-top: 16px;
  display: flex;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
`;

export const Content = styled("div")`
  padding: 0px 24px 24px 24px;
  box-sizing: content-box !important;
  display: flex;
  width: 100%;
  max-width: calc(480px - 48px);
  flex-direction: column;
  align-items: center;
`;

export const OTPContentV2 = styled("div")`
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 64px auto 24px auto;
  padding: 0 24px 0 24px;
  max-width: 480px;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
  width: 100%;
`;

export const OTPContent = styled("div")`
  padding: 48px 24px 24px 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
`;

export const OTPText = styled("div")`
  text-align: center;
`;
export const ResetStateText = styled("div")`
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.blue};
  padding-top: 8px;
`;

export const PhoneInputWrapper = styled("div")`
  display: flex;
  height: 50px;
  margin-bottom: 1rem;
`;

export const ErrorMessage = styled("p")`
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.negative};
  padding: 0 2.5rem;
  text-align: center;
`;
