import React, { useState, Ref } from "react";
import { isAndroid } from "../../../../../../../utils/userAgent";
import { Button } from "../../../../../Button";
import { PhoneInput } from "../../../../../PhoneInput";
import { ButtonWrapper, HideRecaptcha, Container } from "./styles";

type Props = {
  inputRef: Ref<HTMLInputElement | null>;
  inputFocused: boolean;
  errors?: string[];
  loading: boolean;
  googleApiKey: string;
  googleMapsApiKey: string;
  handleCountryChange: (country: string) => void;
  onChange: (prefix: string, phoneNumber: string) => void;
  onFocus: () => void;
  onBlur: () => void;
};

export function PhoneForm({
  errors,
  inputRef,
  inputFocused = false,
  loading = false,
  googleApiKey,
  googleMapsApiKey,
  handleCountryChange,
  onChange,
  onFocus,
  onBlur,
}: Props) {
  const [isValidPhoneFormat, setIsValidPhoneFormat] = useState(false);

  const onPhoneInputChange = (
    prefix: string,
    phoneNumber: string,
    isValid: boolean
  ) => {
    onChange?.(prefix, phoneNumber);

    setIsValidPhoneFormat(isValid);
  };

  return (
    <Container>
      <PhoneInput
        name="phoneNumber"
        googleApiKey={googleApiKey}
        googleMapsApiKey={googleMapsApiKey}
        valid={!errors?.length}
        autoFocus
        inputRef={inputRef}
        errors={errors}
        onCountryChange={handleCountryChange}
        onChange={onPhoneInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      <ButtonWrapper inputFocused={inputFocused}>
        <Button
          title="Get code"
          id="phoneInput.send-email-code"
          type="submit"
          disabled={!isValidPhoneFormat}
          loading={loading}
          text={"Get code"}
          align="center"
        />
        <HideRecaptcha inputFocused={inputFocused} isAndroid={isAndroid} />
      </ButtonWrapper>
    </Container>
  );
}
