import React, { Ref } from "react";
import { isAndroid } from "../../../../../../../utils/userAgent";
import { Button } from "../../../../../Button";
import { TextField } from "../../../../../TextField";
import { ButtonWrapper, Container, HideRecaptcha } from "./styles";
import { validate } from "../../../../../../../utils/emailValidator";

type Props = {
  inputRef: Ref<HTMLInputElement | null>;
  inputFocused: boolean;
  value: string;
  onChange: (email: string) => void;
  onFocus: VoidFunction;
  onBlur: VoidFunction;
  isSsoEnabled: boolean;
  onSsoClicked: VoidFunction;
  errors?: string[];
  loadingCode: boolean;
  loadingSso: boolean;
};

export function EmailForm({
  value,
  onChange,
  errors,
  onFocus,
  onBlur,
  isSsoEnabled,
  onSsoClicked,
  inputRef,
  inputFocused,
  loadingCode,
  loadingSso,
}: Props) {
  return (
    <Container>
      <TextField
        type="email"
        data-testid="email-input"
        value={value}
        onChange={(evt) => onChange(evt.target.value.trim())}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder="example@mail.com"
        inputRef={inputRef}
        errors={errors}
        valid={!errors?.length}
        autoFocus
      />

      <ButtonWrapper inputFocused={inputFocused}>
        <Button
          title="Get code"
          id="phoneInput.send-email-code"
          type="submit"
          disabled={!validate(value)}
          loading={loadingCode}
          text={"Get code"}
          align="center"
        />
        <br />
        <HideRecaptcha inputFocused={inputFocused} isAndroid={isAndroid} />
        {isSsoEnabled && (
          <Button
            title="Login with SSO"
            id="emailInput.sso-login"
            type="button"
            disabled={!validate(value)}
            loading={loadingSso}
            text={"Login using Corporate SSO"}
            align="center"
            color="utility"
            onClick={onSsoClicked}
          />
        )}
      </ButtonWrapper>
    </Container>
  );
}
