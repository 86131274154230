import React from "react";
import { FormHelperText as StyledFormHelperText } from "./styled";

import { FormHelperTextProps } from "@mui/material/FormHelperText";

export type Props = FormHelperTextProps &
  React.PropsWithChildren<{
    id?: string;
    text?: string;
  }>;

export function FormHelperText({ children, text, ...props }: Props) {
  // TODO: Remove this validation, this componen should not be rendered at all instead
  if (!children && !text) return null;

  return (
    <StyledFormHelperText {...props}>
      {children ? children : text}
    </StyledFormHelperText>
  );
}
