import { styled } from "@mui/material/styles";
import { BigSpringLogoIcon } from "../../../../../../icons";

const hideHeaderMaxHeight = 340;

export const MainContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 480px;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
`;

export const TitleContainer = styled("div")`
  text-align: center;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextDark};
`;

export const SubTitleContainer = styled("div")`
  text-align: center;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
`;
export const TopContent = styled("div")`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
`;
export const LowerContent = styled("div")`
  padding: 0px 24px 24px 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 480px;
  margin: auto;
`;

export const BottomSubtext = styled("div")`
  border-top: ${({ theme }) =>
    `1px solid ${theme.kinetic.v2.palette.rgb.grayUiLight}`};
  width: 100%;
  max-width: 432px;
`;

export const Body = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  min-height: 100dvh;
  max-height: 100vh;
  max-height: 100dvh;
  width: 100%;
  color: white;
  background-color: white;
  font-feature-settings: "kern", "liga", "clig" !important;
  font-kerning: auto !important;
  -webkit-font-smoothing: antialiased !important;
  font-variant-ligatures: common-ligatures contextual !important;
  text-rendering: optimizeLegibility !important;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, Helvetica, Arial,
    sans-serif;
`;

export const TopContainer = styled("div")`
  background: white;
  filter: drop-shadow(0px 2px 8px rgba(32, 32, 32, 0.25));
`;

export const LowerSection = styled("div")`
  border-top: 1px solid #d2d2d2;
`;

export const BigSpringLogo = styled(BigSpringLogoIcon)`
  margin-top: 64px;
  width: 160px;
  height: 33px;
  display: flex;
  flex-direction: row;
  justify-self: center;
`;
export const LogoContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const SignUpLink = styled("a")`
  margin-top: 8px;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.blue};
  display: flex;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
`;

export const ButtonContainer = styled("div")`
  margin-top: 16px;
  display: flex;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
`;

export const BottomTitleText = styled("div")`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
  padding-top: 24px;
  padding-bottom: 12px;
`;

export const Content = styled("div")`
  padding: 64px 24px 24px 24px;
  display: flex;
  width: 100%;
  max-width: 400px;
  flex-direction: column;
  align-items: center;
`;

export const ContentV2 = styled("div")`
  padding: 20px 24px 24px 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const SignInSectionTab = styled("button")<{
  active: boolean;
  onClick: () => void;
}>`
  padding: 8px 0px;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  flex: 1;
  & > span {
    ${({ active }) => (active ? `font-weight: 500` : `font-weight: 400`)}
  }
  border-bottom: ${({ active, theme }) =>
    active ? `4px solid ${theme.kinetic.v2.palette.rgb.fluoro}` : null};
  color: ${({ active, theme }) =>
    active
      ? theme.kinetic.v2.palette.rgb.grayTextDark
      : theme.kinetic.v2.palette.rgb.grayTextLighter};
  :hover {
    color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextDark};
    border-bottom: ${({ theme }) =>
      `4px solid ${theme.kinetic.v2.palette.rgb.grayUiLight}`};
  }
  :focus {
    color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextDark};
    border-bottom: ${({ theme }) =>
      `4px solid ${theme.kinetic.v2.palette.rgb.fluoro}`};
  }
  :disabled {
  }
  cursor: pointer;
`;

export const SignInTabContainer = styled("div")`
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-items: space-between;
  width: 100%;
  padding-bottom: 0px;
`;

export const OTPContentV2 = styled("div")`
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 64px 0px 24px 0px;
  padding: 0 24px 0 24px;
  max-width: 480px;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
  width: 100%;
`;

export const OTPContent = styled("div")`
  padding: 48px 24px 24px 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
`;

export const OTPText = styled("div")`
  text-align: center;
`;
export const ResetStateText = styled("div")`
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.blue};
  padding-top: 8px;
`;

export const PhoneInputWrapper = styled("div")`
  display: flex;
  height: 50px;
  margin-bottom: 1rem;
`;

export const ButtonWrapper = styled("div")<{
  inputFocused: boolean;
}>`
  margin-top: 16px;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
  width: 100%;
`;

export const Form = styled("form")<{ phase?: string }>`
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (max-height: ${hideHeaderMaxHeight}px) {
    justify-content: center;
    ${({ phase }) => phase === "confirming" && "padding-bottom: 22px;"}
  }
`;

export const TabbedContainer = styled("div")`
  padding: 20px 24px 0px 24px;
  display: flex;
  width: 100%;
  max-width: 480px;
  flex-direction: column;
  align-items: center;
  align-self: center;
`;

export const HLine = styled("hr")`
  border: 1px solid grey;
  width: 100%;
`;
