import { css, styled, Theme } from "@mui/material/styles";
import InputLabelMui from "@mui/material/InputLabel";
import InputAdornmentMui from "@mui/material/InputAdornment";
import { Body1 } from "../BSPBody1";
import { Body2 } from "../BSPBody2";
import {
  CheckIcon as CheckIconBase,
  WarningIcon as WarningIconBase,
} from "../../../../icons";

// TODO: These shared styles between Input and PhoneInput should be moved to a different folder. Evaluate
// to use form-utils for sharing styles

export const WarningIcon = styled(WarningIconBase)`
  margin-right: 8px;
  margin-top: 3px;
  fill: ${({ theme }) => theme.kinetic.palette.rgb.negative800};
`;

export const ErrorMessageContainer = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-top: 2px;
  color: ${({ theme }) => theme.kinetic.palette.rgb.negative800};
`;

export const InputLabel = styled(InputLabelMui)`
  ${Body2}
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
`;

export const Input = ({ theme }: { theme: Theme }) => css`
  ${Body1}
  position: relative;
  background: ${theme.kinetic.palette.rgb.white};
  width: 100%;
  min-height: 48px;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  border-color: ${theme.kinetic.palette.rgb.gray200};

  input {
    min-height: 46px;
    max-height: 100%;
    padding: ${`0 ${theme.kinetic.paddings[12]} !important`}; // This is used with combobox
  }

  &:disabled,
  &.Mui-disabled,
  &.Input--Disabled {
    background-color: ${theme.kinetic.palette.rgb.gray100};
    color: ${theme.kinetic.v2.palette.rgb.grayTextLighter};
    border-color: ${theme.kinetic.palette.rgb.gray400};
  }
  &:hover:not(.Mui-disabled),
  &.Input--hover:not(.Mui-disabled) {
    border-color: ${theme.kinetic.palette.rgb.blue200};
  }
  &:visited:not(:hover):not(.Mui-disabled),
  &.Input--visited:not(:hover):not(.Mui-disabled) {
    border-color: ${theme.kinetic.v2.palette.rgb.grayTextLighter};
    .MuiInputAdornment-positionStart {
      svg {
        fill: ${theme.kinetic.v2.palette.rgb.grayTextLighter} !important;
      }
    }
  }
  &.Mui-focused,
  &:focus,
  &.Input--focus {
    border-color: ${theme.kinetic.v2.palette.rgb.grayTextLighter} !important;
    .MuiInputAdornment-positionStart {
      svg {
        fill: ${theme.kinetic.palette.rgb.gray900};
      }
    }
  }
  &.Mui-error {
    color: ${theme.kinetic.palette.rgb.negative800};
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const StartInputAdornment = styled(InputAdornmentMui)`
  margin: 0 0 0 10px;
  svg {
    fill: ${({ theme }) => theme.kinetic.palette.rgb.gray400};
  }
`;
export const EndInputAdornment = styled(InputAdornmentMui)`
  margin: 0 10px;
`;
export const CheckIcon = styled(CheckIconBase)`
  fill: ${({ theme }) => theme.kinetic.palette.rgb.positive800};
`;
