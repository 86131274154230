import { useCallback, useEffect, useState } from "react";

export function useTimer(delayInSeconds: number) {
  const [effectCacheKey, setEffectCacheKey] = useState({});
  const [elapsedTime, setElapsedTime] = useState(0);
  const restartTimer = useCallback(() => {
    setEffectCacheKey({});
    setElapsedTime(0);
  }, []);

  useEffect(
    () => startTicking(delayInSeconds, setElapsedTime),
    [delayInSeconds, effectCacheKey]
  );

  return {
    secondsRemaining: delayInSeconds - elapsedTime,
    restartTimer,
  };
}

function startTicking(
  delayInSeconds: number,
  setElapsedTime: (elapsedTime: number) => void
): () => void {
  const intervalId = setInterval(tick, 1000);
  let elapsedTime = 0;

  function tick(): void {
    setElapsedTime((elapsedTime += 1));
    if (delayInSeconds === elapsedTime) {
      clearInterval(intervalId);
    }
  }

  return () => {
    clearInterval(intervalId);
  };
}
