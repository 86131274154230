import { GraphQLError } from "graphql";

export const parseGraphQLError = (e: GraphQLError) => {
  return {
    errorMessage: e.extensions.lokaliseKey || e.message,
    errorName: e.extensions.name || "no error name",
    code: e.extensions.code,
    message: e.message,
  };
};
