import React, { ReactNode } from "react";
import { FormLabelProps } from "@mui/material/FormLabel";
import { FormLabel as StyledFormLabel } from "./styled";

export type Props = FormLabelProps & {
  label?: ReactNode; // TODO: Remove this prop in favor of children
  id?: string;
};

export function FormLabel({ label, ...props }: Props) {
  return <StyledFormLabel {...props}>{label}</StyledFormLabel>;
}
