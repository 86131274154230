import { styled } from "@mui/material/styles";
import { TextField as StyledInput } from "../TextField";

export const InputContainer = styled("div")`
  border: 1px solid #0000003b;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  height: 48px;
  background: white;
  padding-left: 8px;
  box-sizing: border-box;
`;

export const SelectWrapper = styled("div")`
  height: 100%;
  display: flex;
  position: relative;
  flex-basis: 50px;
  flex-shrink: 0;
  justify-content: space-around;
  align-items: center;
  padding-right: 7px;
`;

export const SyntheticSelect = styled("div")`
  font-size: 18px;
`;

export const Carrot = styled("div")`
  content: "";
  right: 1rem;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top-color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextDark};
  transform: translateY(1px);
`;

export const Select = styled("select")`
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

export const InputWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Input = styled(StyledInput)`
  padding: 0;
  border: none;
  border-left: ${({ theme }) =>
    `1px solid ${theme.kinetic.v2.palette.rgb.grayUiLight}`};
  :hover:not(:focus-within) {
    border-left: ${({ theme }) =>
      `1px solid ${theme.kinetic.v2.palette.rgb.grayUiLight}`} !important;
  }
  &.MuiFormControl-root {
    box-sizing: border-box;
  }
  .MuiInputBase-root {
    border: none;
    min-height: 46px;
  }
  fieldset {
    border: none;
  }
`;
